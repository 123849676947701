import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { NgClass } from '@angular/common';

@Component({
  selector: 'ama-checkbox',
  templateUrl: 'ama-checkbox.component.html',
  styleUrls: ['ama-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass]
})
export class AmaCheckboxComponent implements OnInit {
  @Input() public labelText!: string;
  @Input() public position!: string;
  @Input() public themeType!: string;
  @Input() public checked!: boolean;
  @Input() public fontWeight!: string;

  public randomNum!: string;

  @Output() public checkedChange = new EventEmitter<boolean>();

  public ngOnInit() {
    // tslint:disable-next-line: no-submodule-imports
    this.randomNum = uuidv4();
  }

  public emitChangeEvent() {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
