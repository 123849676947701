<label for="checkboxxx-{{ randomNum }}"
       class="ama-checkbox">
  <input type="checkbox"
         id="checkboxxx-{{ randomNum }}"
         (change)="emitChangeEvent()"
         [checked]="checked" />
  <div class="ama-checkbox__label"
       [ngClass]="{ 'ama-checkbox__label--text-right': position === 'right',
       'ama-checkbox__label--font-normal': fontWeight === 'normal'}">
    {{ labelText }}
  </div>
  <div class="ama-checkbox__checkmark-container"
       [ngClass]="{
      'ama-checkbox__checkmark-container--purple': themeType === 'primary',
      'ama-checkbox__checkmark-container--blue': themeType === 'secondary'
  }">
    <span class="ama-checkbox__checkmark"></span>
  </div>
</label>
